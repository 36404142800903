import React from 'react'
import PropTypes from 'prop-types'

import { getEventSummary } from '../../util/events'

export default class ScheduleSummary extends React.Component {
  render() {
    const { event } = this.props
    let summary = null;

    if (event.eventType) {
      if (event.eventType.name.includes('Music') && event.timeline === 'future') {
        return null
      }
  
      summary = event.eventType.name.includes('Sponsors')
        ? event.sponsorsLogoComponent
        : getEventSummary(event.summary, event.excerpt)
    } else {
      summary = getEventSummary(event.summary, event.excerpt)
    }

    return (
      <div className="is-size-6-mobile is-size-6-desktop schedule_event_summary">
        {summary}
      </div>
    )
  }
}

ScheduleSummary.propTypes = {
  event: PropTypes.object,
}
