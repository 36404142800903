import React from 'react'
import PropTypes from 'prop-types'
import { FluxibleIconBlock } from '../FluxibleIcon'

export default class ScheduleLocation extends React.Component {
  render() {
    const { event, placement, isConferenceDate } = this.props
    let location = ''

    if (placement === 'details' && event.location) {
      location = event.location.name
      // super ugly, but all this logic needs to change anyway
      if (
        event.eventType.name.includes('presentation') ||
        event.eventType.name.includes('break') ||
        event.eventType.name.includes('Performance') ||
        event.eventType.name.includes('party') ||
        event.eventType.name.includes('Sponsors')
      )
        location = ''
    } else if (placement === 'header') {
      if (!isConferenceDate) return null
      location = 'CIGI Auditorium'
    }

    return location !== '' ? (
      <div className="fluxible_location">
        <FluxibleIconBlock icon="building" text={location} size="sm" />
      </div>
    ) : null
  }
}

ScheduleLocation.propTypes = {
  event: PropTypes.object,
}
