import React from 'react'
import PropTypes from 'prop-types'
import scrollToElement from 'scroll-to-element'
import moment from 'moment-timezone'

import ScheduleDateHeader from './ScheduleDateHeader'
import ScheduleTime from './ScheduleTime'
import ScheduleDetails from './ScheduleDetails'
import { normalizeObjectList, getImage } from '../../util'
import { RAW_DATE_FORMAT, TZ } from '../../util/events'
import { v4 } from 'uuid'

const eventStyle = {
  present: 'schedule_event_present',
  past: 'schedule_event_past',
  future: 'schedule_event_future',
}

export default class Schedule extends React.Component {
  constructor(props) {
    super(props)

    const { data, edition } = props
    if (!data) return

    const eventsList = normalizeObjectList(
      data,
      [
        'id',
        'excerpt',
        'endDate',
        'startDate',
        'eventType',
        'speakers',
        'name',
        'summary',
        'location', 'speakers',
        { fields: ['slug'] },
      ],
      'id'
    )

    const isTv = edition.editionTypeId === 2

    const events = Object.keys(eventsList)
      .filter(key => eventsList[key].name !== '')
      .map(key => {
        return eventsList[key]
      })
      .sort((a, b) => {
        if (moment.tz(a.startDate, TZ) >= moment.tz(b.startDate, TZ)) {
          return 1
        } else {
          return -1
        }
      })

    let conferenceEnd = moment.tz(edition.endDate, TZ).subtract(0, 'days')

    const conferenceStart = moment.tz(conferenceEnd, TZ).subtract(1, 'days')
    this.addSponsorsLogoToSchedule(events, props.sponsors, conferenceEnd)
    this.fakeDate = props.fakeDate ? moment.tz(props.fakeDate, TZ) : null

    // group events by date
    let eventsByDates = {}
    events.forEach(event => {
      const date = moment.tz(event.startDate, TZ).format(RAW_DATE_FORMAT)
      if (!eventsByDates[date]) {
        eventsByDates[date] = []
      }
      eventsByDates[date].push(event)
    })

    this.state = { eventsByDates, conferenceStart, conferenceEnd, isTv }
    this.stateChange = false
  }

  addSponsorsLogoToSchedule = (events, sponsors) => {
    const sponsorsLists = {
      supremium: [],
      platinum: [],
      gold: [],
      silver: [],
      education: [],
      supporters: [],
    }

    sponsors.forEach(({ node: sponsor }) => {
      const name = sponsor.name
      if (!name) return
      const jsx = (
        <div key={v4()}>
          {sponsor.logo && (
            <figure className="image cool_people-logo">
              { getImage(sponsor.logo, name, sponsor.sponsorType) }
            </figure>
          )}
        </div>
      )
      if(sponsorsLists[sponsor.sponsorType])
        sponsorsLists[sponsor.sponsorType].push(jsx)
    })

    const sponsorEvents = events.filter(event =>
      event.eventType && event.eventType.name.includes('Sponsors')
    )

    // Add Sponsors logo to the sponsor's event
    Object.keys(sponsorsLists).forEach(key => {
      const event = sponsorEvents.find(event =>
        event.name.toLowerCase().includes(key)
      )

      if (!event) return null

      event.sponsorsLogoComponent = (
        <div className={`cool_people ${key}`}>
          {event.summary}
          <div className="cool_people-grid">{sponsorsLists[key]}</div>
        </div>
      )
    })
  }

  updateEventTimeline(eventsByDates, conferenceEnd) {
    if (!eventsByDates) return

    let wasModify = false
    let now
    //const now = moment.tz("2018-09-22 09:42:00", TZ); // Format YYYY-MM-DD HH:mm:ss

    if (this.fakeDate) {
      now = this.fakeDate.add(1, 'minute').clone()
      console.log(this.fakeDate.format()) // it shows the fake data on console
    } else {
      now = moment().tz(TZ)
    }

    Object.keys(eventsByDates).forEach(key => {
      eventsByDates[key].forEach(event => {
        if (event.timeline === 'past') return

        const startDate = moment.tz(event.startDate, TZ)
        const endDate = moment.tz(event.endDate, TZ)
        const oldTimeline = event.timeline

        if (now >= startDate && now < endDate) {
          event.timeline = 'present'
        } else if (now >= endDate && now < conferenceEnd) {
          event.timeline = 'past'
        } else if (now > conferenceEnd) {
          event.timeline = 'finished'
        } else {
          event.timeline = 'future'
        }

        wasModify = oldTimeline !== event.timeline ? true : wasModify
      })
    })
    return wasModify ? eventsByDates : undefined
  }

  componentDidMount() {
    const updateEvents = () => {
      let eventsByDates = this.updateEventTimeline(
        this.state.eventsByDates,
        this.state.conferenceEnd
      )
      if (eventsByDates) {
        this.stateChange = true
        this.setState(prevState => ({
          eventsByDates,
        }))
      }
    }

    const checkTimelineUpdate = () => {
      this.timer = setTimeout(() => {
        updateEvents()
        checkTimelineUpdate()
      }, 60000)
    }

    updateEvents()
    checkTimelineUpdate()
    this.scrollToEvent()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    window.sessionStorage.setItem('scheduleScroll', window.scrollY)
  }

  componentDidUpdate() {
    this.isGoBack = window.sessionStorage.getItem('goBack')
    window.sessionStorage.removeItem('goBack')
    if (!this.stateChange) {
      this.scrollToEvent()
    } else {
      this.stateChange = false
    }
  }

  scrollToEvent() {
    // force to wait until the DOM is ready and finished
    setTimeout(() => {
      const eventDay = moment
        .tz(this.currentEvent.startDate, TZ)
        .format(RAW_DATE_FORMAT)
      const dateHeaderHeight = document.getElementById(eventDay).offsetHeight
      const navbarHeight = document.getElementById('navbar').offsetHeight
      if (!this.isGoBack) {
        scrollToElement(this.currentEvent.div, {
          offset: -(dateHeaderHeight + navbarHeight - 1),
          align: 'top',
          duration: 500,
        })
      } else {
        scrollToElement('#top-of-page', {
          offset: window.sessionStorage.getItem('scheduleScroll') - 1,
          align: 'top',
          duration: 500,
        })
      }
    }, 200)
  }

  render() {
    const { eventsByDates, isTv } = this.state
    if (!eventsByDates) return null

    return (
      <div className="schedule">
        {Object.keys(eventsByDates).map((key, idx) => {
          const eventsList = eventsByDates[key]
          return (
            <div key={v4()}>
              <ScheduleDateHeader
                event={eventsList[0]}
                conferenceStart={this.state.conferenceStart}
                conferenceEnd={this.state.conferenceEnd}
                isTv={isTv}
                first={idx === 0}
              />
              {eventsList.map(event => {
                if (
                  !this.currentEvent ||
                  ((['present', 'past'].includes(event.timeline) ||
                    this.currentEvent.timeline === 'past') &&
                    this.currentEvent.timeline !== 'present')
                ) {
                  this.currentEvent = event
                }

                return (
                  <div
                    key={event.id}
                    ref={section => {
                      if (this.currentEvent === event)
                        this.currentEvent.div = section
                    }}
                  >
                    <div
                      className={`columns is-mobile schedule_event ${
                        eventStyle[event.timeline]
                      }`}
                    >
                      <div className="column is-2-mobile is-2-desktop is-2-tablet schedule_event_time_column">
                        <ScheduleTime event={event} />
                      </div>
                      {/* eslint-disable-next-line no-unused-expressions */}
                      <div className="column schedule_event_details_column">
                        <ScheduleDetails event={event} />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }
}

Schedule.propTypes = {
  data: PropTypes.array,
  speakers: PropTypes.object,
  locations: PropTypes.object,
}
