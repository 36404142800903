import React from 'react'
import PropTypes from 'prop-types'

export default class ScheduleSpeakers extends React.Component {
  render() {
    const { event } = this.props

    if ( event.eventType && 
      (event.eventType.name.includes('break') ||
        event.eventType.name.includes('Music')) &&
      event.timeline === 'future'
    ) {
      return null
    }

    return (
      <div className="is-size-6-mobile schedule_speakers">
        {event.speakers &&
          event.speakers.map(speaker => speaker.name).join(', ')}
      </div>
    )
  }
}

ScheduleSpeakers.propTypes = {
  event: PropTypes.object,
}
