import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import ScheduleLocation from './ScheduleLocation'
import { formatDate, RAW_DATE_FORMAT, TZ } from '../../util/events'

export default class ScheduleDateHeader extends React.Component {
  render() {
    let { event, conferenceStart, conferenceEnd, isTv, first } = this.props

    conferenceStart = moment.tz(conferenceStart, TZ).format(RAW_DATE_FORMAT)
    conferenceEnd = moment.tz(conferenceEnd, TZ).format(RAW_DATE_FORMAT)

    const eventDate = moment.tz(event.startDate, TZ).format(RAW_DATE_FORMAT)

    const isConferenceDate = [conferenceStart, conferenceEnd].includes(
      eventDate
    ) && !isTv

    return (
      <div
        id={eventDate}
        className="columns is-mobile fluxible_date_header schedule_date_header sticky"
      >
        <div className="column">
          {formatDate(event.startDate)}
            <span>
              {" "}
              <span className="event_date_timezone">
                (All times are {moment.tz(TZ).zoneAbbr()})
              </span>
            </span>
          {isConferenceDate && (
            <p className="conference_header">Fluxible Conference</p>
          )}
          <ScheduleLocation
            event={event}
            placement="header"
            isConferenceDate={isConferenceDate}
          />
        </div>
      </div>
    )
  }
}

ScheduleDateHeader.propTypes = {
  event: PropTypes.object,
}
