import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PropTypes from 'prop-types'

import Schedule from '../components/Schedule/Schedule'
import Navbar from '../components/Navbar'
import { getUrlVars } from '../util'

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props

    // get date and time from url
    const params = getUrlVars(this.props.location.href)
    const { date, time } = params
    const fakeDate = date && time ? `${date} ${time}` : null

    if (!data || !data.events) return null

    const { edges: events } = data.events
    const { edges: sponsors } = data.sponsors
    const edition = data.edition

    return (
      <Layout>
        <>
          <Navbar />
          <section className="section homepage">
            <Schedule
              data={events}
              sponsors={sponsors}
              fakeDate={fakeDate}
              edition={edition}
            />
          </section>
        </>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allFluxibleEvents: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allFluxibleSponsors: PropTypes.shape({
      edges: PropTypes.array,
    }),
    fluxibleEditions: PropTypes.object,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($editionId: Int!) {
    events: allFluxibleEvents(
      sort: { order: DESC, fields: [name] }
      filter: { editionId: { eq: $editionId } }
    ) {
      edges {
        node {
          id
          name
          summary
          startDate
          endDate
          eventType {
            name
            displayName
          }
          edition {
            name
          }
          location {
            name
            fields {
              slug
            }
          }
          speakers {
            name
          }
          fields {
            slug
          }
        }
      }
    }
    sponsors: allFluxibleSponsors(
        filter: { editionId: { eq: $editionId } }
        sort: { fields: [name], order: ASC }
    ) {
      edges {
        node {
          name
          description
          sponsorType
          logo {
            name
            altText
            contentType
          }
          fields {
            slug
          }
        }
      }
    }
    edition: fluxibleEditions(alternative_id: { eq: $editionId }) {
      name
      startDate
      endDate
      editionTypeId
      fields {
        slug
      }
    }
  }
`
