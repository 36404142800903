import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

export default class ScheduleTitle extends React.Component {
  render() {
    const { event } = this.props

    const getTitle = () => {
      if (!event.eventType) return event.name
      if (event.eventType.name.includes('break')) {
        if (event.name.includes('Lunch')) {
          return 'Lunch'
        } else if (event.name.includes('Break')) {
          return 'Break'
        } else if (event.name.includes('Welcome')) {
          return 'Welcome and kickoff'
        } else {
          return event.name
        }
      } else if (
        event.eventType.name.includes('Music') &&
        event.timeline === 'future'
      ) {
        return 'Performer to be revealed'
      } else if (event.eventType.name.includes('Sponsors')) {
        return event.name
      } else {
        return (
          <Link id={event.slug} to={event.slug}>
            {event.name}
          </Link>
        )
      }
    }

    const getType = () => {
      if (!event.eventType) return null

      if (
        event.eventType.name.includes('break') ||
        event.eventType.name.includes('party')
      )
        return null

        return <div className="fluxible_event_type">{event.eventType.displayName}</div>
    }

    return (
      <>
        {getType()}
        <div className="schedule_event_title">{getTitle()}</div>
      </>
    )
  }
}

ScheduleTitle.propTypes = {
  event: PropTypes.object,
}
