import React from 'react'
import PropTypes from 'prop-types'

import ScheduleLocation from './ScheduleLocation'
import ScheduleTitle from './ScheduleTitle'
import ScheduleSpeakers from './ScheduleSpeakers'
import ScheduleSummary from './ScheduleSummary'

export default class ScheduleDetails extends React.Component {
  render() {
    const { event } = this.props
    if (!event) return null

    return (
      <>
        <ScheduleTitle event={event} />
        <ScheduleSpeakers event={event} />
        <ScheduleSummary event={event} />
        <ScheduleLocation event={event} placement="details" />
      </>
    )
  }
}

ScheduleDetails.propTypes = {
  event: PropTypes.object,
}
