import React from 'react'
import PropTypes from 'prop-types'

import { formatHours } from '../../util/events'

export default class ScheduleTime extends React.Component {
  render() {
    const { event } = this.props

    return (
      <div className="schedule_event_time_column">
        <div className="schedule_start_time">
          {formatHours(event.startDate)}
        </div>
        <div className="schedule_end_time">{formatHours(event.endDate)}</div>
      </div>
    )
  }
}

ScheduleTime.propTypes = {
  event: PropTypes.object,
}
